<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">
            {{ wedding.man.surname }} & {{ wedding.woman.surname }}
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      ref="audio"
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
    />
    <AGWHero
      overlay
      :background="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan" />
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes" />
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60" />
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.8601145772664!2d112.7398604!3d-7.2567569999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9620aad4959%3A0xe2cdd004e045ee68!2sUPT.%20TAMAN%20BUDAYA%20JAWA%20TIMUR!5e0!3m2!1sen!2ssg!4v1724072525160!5m2!1sen!2ssg"
        directionUrl="https://maps.app.goo.gl/9PRucHc2bW3ZWfxh8"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <!-- <Himbauan /> -->
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ height: `${$root.dividerHeight}px` }"></div>

    <Footer ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
import Audio from "@/assets/audio/backsound.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
// import Himbauan from "@/components/Himbauan.vue";
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Ceremonyku.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    // Himbauan,
    Footer
  },
  data() {
    return {
      title: "Pernikahan",
      backgrounds: [
        "https://ik.imagekit.io/aryraditya/%20putuarya-meimei/RumahVisuals-3.jpg?updatedAt=1723391582419",
        "https://ik.imagekit.io/aryraditya/%20putuarya-meimei/RumahVisuals.jpg?updatedAt=1723391587984",
        "https://ik.imagekit.io/aryraditya/%20putuarya-meimei/RumahVisuals-6.jpg?updatedAt=1723391582486"
      ],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: "I Putu Arya Agus Sardi, S.Sn",
          surname: "Putu Arya",
          parents: "I Made Sudira, Ni Luh Sutriani",
          description: "Putra pertama",
          address: "Jl. Sulatri, No 48, Kesiman Petilan, Denpasar Timur",
          photo:
            "https://ik.imagekit.io/aryraditya/%20putuarya-meimei/RumahVisuals-15.jpg?updatedAt=1723391942361&tr=w-400,h-500,fo-custom,cm-extract"
        },
        woman: {
          name: "Meidinar Adellia Sasongko, S.Sn",
          surname: "Meidinar",
          parents: "Dady Agung Sasongko & Anik Sasongko",
          address: "Surabaya",
          description: "Putri pertama",
          photo:
            "https://ik.imagekit.io/aryraditya/%20putuarya-meimei/RumahVisuals-12.jpg?updatedAt=1723391952303&tr=w-712,h-888,fo-custom,cm-extract"
        }
      },
      location:
        "UPT. TAMAN BUDAYA JAWA TIMUR. Jl. Genteng Kali No.85, Genteng, Kec. Genteng, Surabaya, Jawa Timur, Indonesia",
      time: {
        start: this.$moment("2024-09-08 18:00"),
        end: "Selesai"
      },
      mepandes: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
